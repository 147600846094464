import React from "react";
import styled from "styled-components";
import EzReadMore from "../components/ReUsables/EzReadMore";
import { RespContainer } from "../styles/common";
import { Devices } from "../styles/variables";

const notFound: React.FC<any> = ({ pageContext, ...props }) => {
  return (
    <>
      <RespContainer>
        <Wrapper>
          <NotFoundImageWrapper>
            <NotFoundImage src="/images/404.png" />
          </NotFoundImageWrapper>
          <NotFoundTextsWrapper>
            <Title404>404</Title404>
            <TitleNotFound>Page Not Found!</TitleNotFound>
            <InfoText>
              We’re sorry, the page you requested could not be found.
            </InfoText>
            <HelperLinksWrapper>
              <HelperText>Visit our</HelperText>
              <EzReadMore to={"/"} customText={"Home Page"} />
            </HelperLinksWrapper>

            <HelperLinksWrapper>
              <HelperText>Use our</HelperText>
              <EzReadMore to={"/sitemap"} customText={"Sitemap"} />
            </HelperLinksWrapper>
          </NotFoundTextsWrapper>
        </Wrapper>
      </RespContainer>
    </>
  );
};

export default notFound;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 70px;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    flex-direction: row;
  }
`;

const NotFoundImageWrapper = styled.div`
  margin-bottom: 44px;
  margin-top: 60px;
  height: 300px;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    height: 640px;
  }
`;

const NotFoundImage = styled.img`
  object-fit: contain;
  height: 100%;
  width: 100%;
`;

const NotFoundTextsWrapper = styled.div``;

const Title404 = styled.h1`
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 5px;
`;

const TitleNotFound = styled.h2`
  text-align: center;
  font-weight: bold;
  letter-spacing: -1px;
`;

const InfoText = styled.p`
  font-size: 17.5px;
  margin-top: 22px;
  text-align: center;
  line-height: 190%;
`;

const HelperLinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 6px;

  > a {
    display: flex;
    align-items: center;
    margin-left: 12px;
    font-weight: 600;
    font-size: 18px;
  }
`;

const HelperText = styled.p`
  font-size: 18px;
  margin-bottom: 0;
`;
